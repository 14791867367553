<template>
    <div class="products_page">
        <custom-nav-bar></custom-nav-bar>
        <img class="products_banner"
            src="https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/pc/products_banner.png" alt="">
        <div ref="container" class="container">
            <div class="item" :ref="`product${index}`" :id="`product-${item.id}`" v-for="(item, index) of curData"
                :key="index">
                <div class="title_wrap">
                    <div :ref="`titleWrap${index}`"
                        class="wrapper HurmeGeometricSans3_Bold flex_align_center flex_justify_between">
                        <div class="flex_align_end">
                            <h1>LEDO</h1>
                            <h2>{{ item.abbreviation }}</h2>
                        </div>
                        <div class="open_btn click_cursor" @click="handleCollapse(item.isHide, item.id)">
                            <img class="absolute_all_0 w_h_100" :class="item.isHide ? 'actived' : ''"
                                src="@/assets/img_pc/collapse.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="wrapper">
                        <div class="inspiration_wrap flex_justify_between">
                            <div class="inspiration flex_col flex_align_start">
                                <h3 class="HurmeGeometricSans3_SemiBold">Design concept</h3>
                                <p>{{ item.inspiration }}</p>
                                <img class="product_img_1" :src="item.productImgs[0]" alt="">
                            </div>
                            <img class="product_img_2" :src="item.productImgs[1]" alt="">
                        </div>
                        <div class="second_title_wrap flex_center_center">
                            <img class="product_img_3" :src="item.productImgs[2]" alt="">
                            <span class="second_title ">{{ item.secondTitle }}</span>
                        </div>
                        <img class="product_img_4" :src="item.productImgs[3]" alt="">
                        <p class="propaganda text_center">{{ item.propaganda }}</p>
                    </div>
                </div>
            </div>
        </div>
        <scroll-bar></scroll-bar>
        <foot></foot>
        <to-top></to-top>
    </div>
</template>

<script>
import customNavBar from "@/components/pc/custom-nav-bar.vue";
import scrollBar from "@/components/pc/scroll-bar.vue"
import foot from "@/components/pc/foot.vue"
import toTop from "@/components/pc/to-top.vue"

import { mapState } from 'vuex'

export default {
    name: 'products',
    data() {
        return {
            curData: [],
            clientHeight: 0, // 浏览器可见区域高度
            titleHeight: 0 // 每个item的title_wrap的高度
        }
    },
    mounted() {
        let id = this.$route.params.id
        if (id) {
            this.scrollById(id)
        }

        this.clientHeight = window.innerHeight || document.documentElement.clientHeight

        document.addEventListener('scroll', this.handleScroll1)
        document.addEventListener('scroll', this.handleScroll2)
        window.addEventListener('resize', this.onResize)
    },
    destroyed() {
        document.removeEventListener('scroll', this.handleScroll1)
        document.removeEventListener('scroll', this.handleScroll2)
        window.removeEventListener('resize', this.onResize)
    },
    computed: {
        ...mapState([
            'productsData'
        ])
    },
    watch: {
        productsData: {
            handler(newVal) {
                let curData = []
                this.productsData.map((item, index) => {
                    curData.push(Object.assign({}, item, { isHide: false, offsetTop: 0, offsetHeight: 0 }))
                })
                 this.curData = curData
                if (curData.length > 0) {
                    this.$nextTick(() => {
                        this.titleHeight = this.$refs[`titleWrap0`][0].offsetHeight
                        this.curData.forEach((value, index) => {
                            value.offsetTop = this.$refs[`product${index}`][0].offsetTop
                            value.offsetHeight = this.$refs[`product${index}`][0].offsetHeight
                        });
                    })
                }
            },
            immediate: true
        }
    },
    methods: {
        handleScroll1() {
            let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            let clientHeight = this.clientHeight
            let titleHeight = this.titleHeight
            let curData = this.curData
            let maxLength = curData.length
            let target
            let isAbsoluted = false
            let isFixed = false
            let element
            for (let index = maxLength - 1; index >= 0; index--) {
                target = curData[index]
                element = this.$refs[`titleWrap${index}`][0]
                if (isAbsoluted || isFixed) {
                    element.style.position = 'absolute'
                    element.style.bottom = '0'
                    element.style.top = 'auto'
                    return
                }
                if (scrollTop > (target.offsetTop + target.offsetHeight - titleHeight)) {
                    isAbsoluted = true
                    element.style.position = 'absolute'
                    element.style.bottom = '0'
                    element.style.top = 'auto'
                } else if (scrollTop > target.offsetTop) {
                    isFixed = true
                    element.style.position = 'fixed'
                    element.style.bottom = 'auto'
                    element.style.top = '0'
                } else {
                    element.style.position = 'relative'
                    element.style.bottom = '0'
                    element.style.top = '0'
                }
            }
        },
        handleScroll2() {
            let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            let curData = this.curData
            let maxLength = curData.length
            let isHide = false
            let clientHeight = this.clientHeight
            let target
            for (let index = maxLength - 1; index >= 0; index--) {
                if (index === maxLength - 1) {
                    curData[index].isHide = true
                } else {
                    if (isHide) {
                        curData[index].isHide = isHide
                    } {
                        target = curData[index + 1]

                        if (scrollTop > (target.offsetTop - clientHeight + this.titleHeight)) {
                            isHide = true
                        } else {
                            isHide = false
                        }
                        curData[index].isHide = isHide
                    }
                }
            }
        },
        onResize() {
            setTimeout(() => {
                if (this.curData.length > 0) {
                    this.titleHeight = this.$refs[`titleWrap0`][0].offsetHeight
                    this.curData.forEach((value, index) => {
                        value.offsetTop = this.$refs[`product${index}`][0].offsetTop
                        value.offsetHeight = this.$refs[`product${index}`][0].offsetHeight
                    });
                }
            }, 500);
        },
        handleCollapse(isHide, id) {
            let toId
            if (isHide) {
                toId = `product-${id}`
            } else {
                toId = `product-${id + 1}`
            }
            this.scrollById(toId)
        },
        scrollById(id) {
            let scrollHeight = document.getElementById(id).offsetTop
            setTimeout(() => {
                window.scrollTo(0, scrollHeight)
            });
        }
    },
    components: {
        customNavBar,
        scrollBar,
        foot,
        toTop
    }
}
</script>

<style lang="scss" scoped>
@import "@/style/mixin.scss";

.products_page {
    color: #EEEDE8;
    background: #2B2B2B;
    background-repeat: no-repeat;

    .products_banner {
        width: 100%;
        height: formatPX_1920(1191px);
    }

    .container {
        padding-top: formatPX_1920(100px);

        .item {
            position: relative;
            padding-bottom: formatPX_1920(160px);

            .title_wrap {
                width: 100%;
                height: formatPX_1920(180px);
                color: #00FF00;

                .wrapper {
                    width: 100%;
                    height: formatPX_1920(180px);
                    left: 0;
                    background-color: #2B2B2B;
                    padding: 0 formatPX_1920(242px);
                }

                h1 {
                    line-height: formatPX_1920(109px);
                    font-size: formatPX_1920(90px);
                    margin-right: formatPX_1920(40px);
                }

                h2 {
                    line-height: formatPX_1920(99px);
                    font-size: formatPX_1920(66px);
                }

                .open_btn {
                    position: relative;
                    width: formatPX_1920(88px);
                    height: formatPX_1920(88px);

                    img {
                        transition: transform 0.3s;

                        &.actived {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            .content {
                padding: formatPX_1920(100px) formatPX_1920(242px) 0;
                /* height: formatPX_1920(1730px); */
                /* overflow: hidden;
                    transition: height 0.3s ease-in-out;
                    &.hide {
                        height: 0;
                    } */
                /* .wrapper {
                        height: formatPX_1920(1730px);
                    } */

                .inspiration_wrap {
                    padding: 0 formatPX_1920(118px);

                    .inspiration {
                        width: formatPX_1920(460px);

                        h3 {
                            line-height: formatPX_1920(58px);
                            font-size: formatPX_1920(48px);
                            color: #fff;
                        }

                        p {
                            line-height: formatPX_1920(60px);
                            font-size: formatPX_1920(24px);
                            text-indent: 2em;
                            margin: formatPX_1920(90px) 0 auto;
                        }

                        .product_img_1 {
                            width: formatPX_1920(278px);
                            height: formatPX_1920(78px);
                        }
                    }

                    .product_img_2 {
                        width: formatPX_1920(568px);
                        height: formatPX_1920(710px);
                    }
                }

                .second_title_wrap {
                    margin: formatPX_1920(180px) 0 formatPX_1920(48px);

                    .product_img_3 {
                        width: formatPX_1920(90px);
                        height: formatPX_1920(90px);
                        margin-right: formatPX_1920(25px);
                    }

                    .second_title {
                        font-size: formatPX_1920(32px);
                    }
                }

                .product_img_4 {
                    width: 100%;
                    height: formatPX_1920(474px);
                }

                .propaganda {
                    width: formatPX_1920(1200px);
                    line-height: formatPX_1920(48px);
                    font-size: formatPX_1920(24px);
                    margin: formatPX_1920(40px) auto 0;
                }
            }
        }
    }
}
</style>